<template>
  <h4>{{type}}</h4>
  <div class="row align-items-center">
    <div class="col">
      <h5>
{{ $t("customRows.customRows") }}
</h5>
    </div>
  </div>
  <OneByOneTable
    v-model:rows="rows"
    v-model:items="items"
    type="row"
    :show-edit="true"
    v-on:edit="rowModal"
    v-on:addbtn="rowModal"
    v-on:delete="deleteRowModal"
  />
  <CustomRowModal
    ref="editModal"
    v-model="selectedRow"
    v-on:newItem="addItem"
    v-model:type="typeData"
  />
  <ConfirmModal
    ref="rowDeleteModal"
    :title="$t(`customRows.deleteRow`)"
    :text="`<ul><li>You will delete ${deleteObject?.name}<b></li><li>This will delete the ${deleteObject?.name} values added to partners</li><li>You can't undo this action</li></ul><p>Are you sure you want to delete?</p>`"
    :cancel-button="`No, keep ${deleteObject?.name}`"
    :ok-button="`Yes, delete ${deleteObject?.name}`"
    v-on:success="deleteRow"
  />
</template>

<script>
import http from "../../../modules/http";
import OneByOneTable from "../../lists/OneByOneTable";
import CustomRowModal from "../../modals/CustomRowModal";
import ConfirmModal from "../../modals/ConfirmModal";

export default {
  name: "CustomRows",
  components: { ConfirmModal, CustomRowModal, OneByOneTable },
  props: {
    activeLanguages: Array,
    selectedLanguage: String,
    type: String,
  },
  data() {
    return {
      deleteObject: {},
      typeData: this.type,
      rows: [
        {
          name: "ID",
          key: "number",
          type: "id",
          show: false,
        },
        {
          name: "Name",
          key: "name",
          type: "string",
          show: true,
        },
      ],
      items: [],
      selectedRow: undefined,
    };
  },
  watch: {
    type() {
      this.typeData = this.type;
      this.load();
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.items = [];
      http.fetch("/custom-rows?path=" + this.type).then((data) => {
        this.items = data;
      });
    },
    addItem(data) {
      this.items.unshift(data);
    },
    rowModal(id) {
      this.selectedRow = this.items[this.items.findIndex((e) => e.id === id)];
      this.$refs.editModal.showModal();
    },
    deleteRow() {
      http
        .fetch("/custom-rows/" + this.deleteObject.id, {}, true, "DELETE")
        .then(() => {
          let i = this.items.indexOf(this.deleteObject);
          if (i > -1) {
            this.items.splice(i, 1);
          }
        });
    },
    deleteRowModal(e) {
      this.deleteObject = e;
      this.$refs.rowDeleteModal.showModal();
    },
  },
};
</script>
